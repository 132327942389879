export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const CommonsAutoFormatTextField = () => import('../../components/Commons/AutoFormatTextField.vue' /* webpackChunkName: "components/commons-auto-format-text-field" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCard = () => import('../../components/Commons/BankCard.vue' /* webpackChunkName: "components/commons-bank-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsBankCryptoCard = () => import('../../components/Commons/BankCryptoCard.vue' /* webpackChunkName: "components/commons-bank-crypto-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsBonusCard = () => import('../../components/Commons/BonusCard.vue' /* webpackChunkName: "components/commons-bonus-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsBonusCardLoading = () => import('../../components/Commons/BonusCardLoading.vue' /* webpackChunkName: "components/commons-bonus-card-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsChangeLanguageLoading = () => import('../../components/Commons/ChangeLanguageLoading.vue' /* webpackChunkName: "components/commons-change-language-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsCopyButton = () => import('../../components/Commons/CopyButton.vue' /* webpackChunkName: "components/commons-copy-button" */).then(c => wrapFunctional(c.default || c))
export const CommonsCustomQrCode = () => import('../../components/Commons/CustomQrCode.vue' /* webpackChunkName: "components/commons-custom-qr-code" */).then(c => wrapFunctional(c.default || c))
export const CommonsDatePicker = () => import('../../components/Commons/DatePicker.vue' /* webpackChunkName: "components/commons-date-picker" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadBanner = () => import('../../components/Commons/DownloadBanner.vue' /* webpackChunkName: "components/commons-download-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadOs = () => import('../../components/Commons/DownloadOs.vue' /* webpackChunkName: "components/commons-download-os" */).then(c => wrapFunctional(c.default || c))
export const CommonsDownloadPage = () => import('../../components/Commons/DownloadPage.vue' /* webpackChunkName: "components/commons-download-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsFilterForm = () => import('../../components/Commons/FilterForm.vue' /* webpackChunkName: "components/commons-filter-form" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameIframeEmbed = () => import('../../components/Commons/GameIframeEmbed.vue' /* webpackChunkName: "components/commons-game-iframe-embed" */).then(c => wrapFunctional(c.default || c))
export const CommonsGameLoadFailed = () => import('../../components/Commons/GameLoadFailed.vue' /* webpackChunkName: "components/commons-game-load-failed" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBanner = () => import('../../components/Commons/InviteAndEarnBanner.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner" */).then(c => wrapFunctional(c.default || c))
export const CommonsInviteAndEarnBannerDesktop = () => import('../../components/Commons/InviteAndEarnBannerDesktop.vue' /* webpackChunkName: "components/commons-invite-and-earn-banner-desktop" */).then(c => wrapFunctional(c.default || c))
export const CommonsLanguageSwitcher = () => import('../../components/Commons/LanguageSwitcher.vue' /* webpackChunkName: "components/commons-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const CommonsLoading = () => import('../../components/Commons/Loading.vue' /* webpackChunkName: "components/commons-loading" */).then(c => wrapFunctional(c.default || c))
export const CommonsMaintenance = () => import('../../components/Commons/Maintenance.vue' /* webpackChunkName: "components/commons-maintenance" */).then(c => wrapFunctional(c.default || c))
export const CommonsNoBonusCard = () => import('../../components/Commons/NoBonusCard.vue' /* webpackChunkName: "components/commons-no-bonus-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsNoSupportPage = () => import('../../components/Commons/NoSupportPage.vue' /* webpackChunkName: "components/commons-no-support-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNotFoundPage = () => import('../../components/Commons/NotFoundPage.vue' /* webpackChunkName: "components/commons-not-found-page" */).then(c => wrapFunctional(c.default || c))
export const CommonsNuxtImage = () => import('../../components/Commons/NuxtImage.vue' /* webpackChunkName: "components/commons-nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const CommonsPopup = () => import('../../components/Commons/Popup.vue' /* webpackChunkName: "components/commons-popup" */).then(c => wrapFunctional(c.default || c))
export const CommonsPromotionHistoryCard = () => import('../../components/Commons/PromotionHistoryCard.vue' /* webpackChunkName: "components/commons-promotion-history-card" */).then(c => wrapFunctional(c.default || c))
export const CommonsSkeletonLoader = () => import('../../components/Commons/SkeletonLoader.vue' /* webpackChunkName: "components/commons-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonsThemeMode = () => import('../../components/Commons/ThemeMode.vue' /* webpackChunkName: "components/commons-theme-mode" */).then(c => wrapFunctional(c.default || c))
export const CommonsVTextFieldCurrency = () => import('../../components/Commons/VTextFieldCurrency.vue' /* webpackChunkName: "components/commons-v-text-field-currency" */).then(c => wrapFunctional(c.default || c))
export const CommonsVerticalFloatingIcons = () => import('../../components/Commons/VerticalFloatingIcons.vue' /* webpackChunkName: "components/commons-vertical-floating-icons" */).then(c => wrapFunctional(c.default || c))
export const Theme1Announcement = () => import('../../components/Theme1/Announcement.vue' /* webpackChunkName: "components/theme1-announcement" */).then(c => wrapFunctional(c.default || c))
export const Theme1Cards = () => import('../../components/Theme1/Cards.vue' /* webpackChunkName: "components/theme1-cards" */).then(c => wrapFunctional(c.default || c))
export const Theme1ContentPageFooter = () => import('../../components/Theme1/ContentPageFooter.vue' /* webpackChunkName: "components/theme1-content-page-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme1FeaturedGame = () => import('../../components/Theme1/FeaturedGame.vue' /* webpackChunkName: "components/theme1-featured-game" */).then(c => wrapFunctional(c.default || c))
export const Theme1FilterForm = () => import('../../components/Theme1/FilterForm.vue' /* webpackChunkName: "components/theme1-filter-form" */).then(c => wrapFunctional(c.default || c))
export const Theme1MainCard = () => import('../../components/Theme1/MainCard.vue' /* webpackChunkName: "components/theme1-main-card" */).then(c => wrapFunctional(c.default || c))
export const Theme1Menubar = () => import('../../components/Theme1/Menubar.vue' /* webpackChunkName: "components/theme1-menubar" */).then(c => wrapFunctional(c.default || c))
export const Theme1ProfileLayout = () => import('../../components/Theme1/ProfileLayout.vue' /* webpackChunkName: "components/theme1-profile-layout" */).then(c => wrapFunctional(c.default || c))
export const Theme1Slider = () => import('../../components/Theme1/Slider.vue' /* webpackChunkName: "components/theme1-slider" */).then(c => wrapFunctional(c.default || c))
export const Theme1Submenu = () => import('../../components/Theme1/Submenu.vue' /* webpackChunkName: "components/theme1-submenu" */).then(c => wrapFunctional(c.default || c))
export const CommonsPhoneInput = () => import('../../components/Commons/PhoneInput/index.vue' /* webpackChunkName: "components/commons-phone-input" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesCircle = () => import('../../components/Commons/Pices/Circle.vue' /* webpackChunkName: "components/commons-pices-circle" */).then(c => wrapFunctional(c.default || c))
export const CommonsPicesStraight = () => import('../../components/Commons/Pices/Straight.vue' /* webpackChunkName: "components/commons-pices-straight" */).then(c => wrapFunctional(c.default || c))
export const CommonsFinanceDisplayBankQr = () => import('../../components/Commons/finance/DisplayBankQr.vue' /* webpackChunkName: "components/commons-finance-display-bank-qr" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopCards = () => import('../../components/Theme1/Desktop/Cards.vue' /* webpackChunkName: "components/theme1-desktop-cards" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopDepositPage = () => import('../../components/Theme1/Desktop/DepositPage.vue' /* webpackChunkName: "components/theme1-desktop-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopDeposits = () => import('../../components/Theme1/Desktop/Deposits.vue' /* webpackChunkName: "components/theme1-desktop-deposits" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopFavoriteGame = () => import('../../components/Theme1/Desktop/FavoriteGame.vue' /* webpackChunkName: "components/theme1-desktop-favorite-game" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopFooter = () => import('../../components/Theme1/Desktop/Footer.vue' /* webpackChunkName: "components/theme1-desktop-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopMenuBar = () => import('../../components/Theme1/Desktop/MenuBar.vue' /* webpackChunkName: "components/theme1-desktop-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNav = () => import('../../components/Theme1/Desktop/Nav.vue' /* webpackChunkName: "components/theme1-desktop-nav" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavBar = () => import('../../components/Theme1/Desktop/NavBar.vue' /* webpackChunkName: "components/theme1-desktop-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopPromotion = () => import('../../components/Theme1/Desktop/Promotion.vue' /* webpackChunkName: "components/theme1-desktop-promotion" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopPromotionDetail = () => import('../../components/Theme1/Desktop/PromotionDetail.vue' /* webpackChunkName: "components/theme1-desktop-promotion-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopRegisterForm = () => import('../../components/Theme1/Desktop/RegisterForm.vue' /* webpackChunkName: "components/theme1-desktop-register-form" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopSubMenu = () => import('../../components/Theme1/Desktop/SubMenu.vue' /* webpackChunkName: "components/theme1-desktop-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopWithdrawals = () => import('../../components/Theme1/Desktop/Withdrawals.vue' /* webpackChunkName: "components/theme1-desktop-withdrawals" */).then(c => wrapFunctional(c.default || c))
export const Theme1MobileCards = () => import('../../components/Theme1/Mobile/Cards.vue' /* webpackChunkName: "components/theme1-mobile-cards" */).then(c => wrapFunctional(c.default || c))
export const Theme1MobileFooter = () => import('../../components/Theme1/Mobile/Footer.vue' /* webpackChunkName: "components/theme1-mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const Theme1MobileNavBar = () => import('../../components/Theme1/Mobile/NavBar.vue' /* webpackChunkName: "components/theme1-mobile-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesDepositApply = () => import('../../components/Theme1/Pages/DepositApply.vue' /* webpackChunkName: "components/theme1-pages-deposit-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesMyBonus = () => import('../../components/Theme1/Pages/MyBonus.vue' /* webpackChunkName: "components/theme1-pages-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesPlayerProfile = () => import('../../components/Theme1/Pages/PlayerProfile.vue' /* webpackChunkName: "components/theme1-pages-player-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme1PagesWithdrawalApply = () => import('../../components/Theme1/Pages/WithdrawalApply.vue' /* webpackChunkName: "components/theme1-pages-withdrawal-apply" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavLoginPopup = () => import('../../components/Theme1/Desktop/Nav/LoginPopup.vue' /* webpackChunkName: "components/theme1-desktop-nav-login-popup" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavMessageDropdown = () => import('../../components/Theme1/Desktop/Nav/MessageDropdown.vue' /* webpackChunkName: "components/theme1-desktop-nav-message-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopNavProfileDropdown = () => import('../../components/Theme1/Desktop/Nav/ProfileDropdown.vue' /* webpackChunkName: "components/theme1-desktop-nav-profile-dropdown" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesBankDetail = () => import('../../components/Theme1/Desktop/Profiles/BankDetail.vue' /* webpackChunkName: "components/theme1-desktop-profiles-bank-detail" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositPage = () => import('../../components/Theme1/Desktop/Profiles/DepositPage.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposit-page" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyBonus = () => import('../../components/Theme1/Desktop/Profiles/MyBonus.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-bonus" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyHistory = () => import('../../components/Theme1/Desktop/Profiles/MyHistory.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-history" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyMessage = () => import('../../components/Theme1/Desktop/Profiles/MyMessage.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-message" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesMyProfile = () => import('../../components/Theme1/Desktop/Profiles/MyProfile.vue' /* webpackChunkName: "components/theme1-desktop-profiles-my-profile" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesReferFriend = () => import('../../components/Theme1/Desktop/Profiles/ReferFriend.vue' /* webpackChunkName: "components/theme1-desktop-profiles-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesResetPassword = () => import('../../components/Theme1/Desktop/Profiles/ResetPassword.vue' /* webpackChunkName: "components/theme1-desktop-profiles-reset-password" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesWithdrawalPage = () => import('../../components/Theme1/Desktop/Profiles/WithdrawalPage.vue' /* webpackChunkName: "components/theme1-desktop-profiles-withdrawal-page" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositsBankTransfer = () => import('../../components/Theme1/Desktop/Profiles/Deposits/BankTransfer.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposits-bank-transfer" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositsCryptoPayment = () => import('../../components/Theme1/Desktop/Profiles/Deposits/CryptoPayment.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposits-crypto-payment" */).then(c => wrapFunctional(c.default || c))
export const Theme1DesktopProfilesDepositsOnlineBank = () => import('../../components/Theme1/Desktop/Profiles/Deposits/OnlineBank.vue' /* webpackChunkName: "components/theme1-desktop-profiles-deposits-online-bank" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
