import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7152215f = () => interopDefault(import('../pages/theme-1/favorite-games/index.vue' /* webpackChunkName: "pages/theme-1/favorite-games/index" */))
const _3bcb9ea9 = () => interopDefault(import('../pages/theme-1/forgot-password.vue' /* webpackChunkName: "pages/theme-1/forgot-password" */))
const _b5ac4766 = () => interopDefault(import('../pages/theme-1/my-bonus/index.vue' /* webpackChunkName: "pages/theme-1/my-bonus/index" */))
const _28334ccf = () => interopDefault(import('../pages/theme-1/no-support.vue' /* webpackChunkName: "pages/theme-1/no-support" */))
const _9e908fa8 = () => interopDefault(import('../pages/theme-1/not-found.vue' /* webpackChunkName: "pages/theme-1/not-found" */))
const _ecd8b4e2 = () => interopDefault(import('../pages/theme-1/play-game.vue' /* webpackChunkName: "pages/theme-1/play-game" */))
const _afb60eec = () => interopDefault(import('../pages/theme-1/promotion/index.vue' /* webpackChunkName: "pages/theme-1/promotion/index" */))
const _4b9a4dda = () => interopDefault(import('../pages/theme-1/refer-friend/index.vue' /* webpackChunkName: "pages/theme-1/refer-friend/index" */))
const _294e3c6c = () => interopDefault(import('../pages/theme-1/referral/index.vue' /* webpackChunkName: "pages/theme-1/referral/index" */))
const _e5f14ea2 = () => interopDefault(import('../pages/theme-1/register.vue' /* webpackChunkName: "pages/theme-1/register" */))
const _73e62828 = () => interopDefault(import('../pages/theme-1/reset-password/index.vue' /* webpackChunkName: "pages/theme-1/reset-password/index" */))
const _6e09fd87 = () => interopDefault(import('../pages/theme-1/history/transaction.vue' /* webpackChunkName: "pages/theme-1/history/transaction" */))
const _7394f650 = () => interopDefault(import('../pages/theme-1/player/banks/index.vue' /* webpackChunkName: "pages/theme-1/player/banks/index" */))
const _7651e4b9 = () => interopDefault(import('../pages/theme-1/player/bet-history/index.vue' /* webpackChunkName: "pages/theme-1/player/bet-history/index" */))
const _7432c82c = () => interopDefault(import('../pages/theme-1/player/deposits/index.vue' /* webpackChunkName: "pages/theme-1/player/deposits/index" */))
const _edb8323e = () => interopDefault(import('../pages/theme-1/player/messages/index.vue' /* webpackChunkName: "pages/theme-1/player/messages/index" */))
const _09e6e787 = () => interopDefault(import('../pages/theme-1/player/profile.vue' /* webpackChunkName: "pages/theme-1/player/profile" */))
const _5ed7ae10 = () => interopDefault(import('../pages/theme-1/player/wallets.vue' /* webpackChunkName: "pages/theme-1/player/wallets" */))
const _980d6782 = () => interopDefault(import('../pages/theme-1/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-1/player/withdrawals/index" */))
const _e80a0e8a = () => interopDefault(import('../pages/theme-1/providers/games/index.vue' /* webpackChunkName: "pages/theme-1/providers/games/index" */))
const _1a5f65f6 = () => interopDefault(import('../pages/theme-1/index.vue' /* webpackChunkName: "pages/theme-1/index" */))
const _79d5ede3 = () => interopDefault(import('../pages/theme-1/providers/games/_id.vue' /* webpackChunkName: "pages/theme-1/providers/games/_id" */))
const _3d0089db = () => interopDefault(import('../pages/theme-1/Information/_id.vue' /* webpackChunkName: "pages/theme-1/Information/_id" */))
const _7ccb9572 = () => interopDefault(import('../pages/theme-1/promotion/_id.vue' /* webpackChunkName: "pages/theme-1/promotion/_id" */))
const _6bea3b1e = () => interopDefault(import('../pages/theme-1/providers/_id.vue' /* webpackChunkName: "pages/theme-1/providers/_id" */))
const _d75c9e30 = () => interopDefault(import('../pages/theme-1/_type/_id.vue' /* webpackChunkName: "pages/theme-1/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/favorite-games",
    component: _7152215f,
    name: "favorite-games"
  }, {
    path: "/forgot-password",
    component: _3bcb9ea9,
    name: "forgot-password"
  }, {
    path: "/my-bonus",
    component: _b5ac4766,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _28334ccf,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _9e908fa8,
    name: "not-found"
  }, {
    path: "/play-game",
    component: _ecd8b4e2,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _afb60eec,
    name: "promotion"
  }, {
    path: "/refer-friend",
    component: _4b9a4dda,
    name: "refer-friend"
  }, {
    path: "/referral",
    component: _294e3c6c,
    name: "referral"
  }, {
    path: "/register",
    component: _e5f14ea2,
    name: "register"
  }, {
    path: "/reset-password",
    component: _73e62828,
    name: "reset-password"
  }, {
    path: "/history/transaction",
    component: _6e09fd87,
    name: "history-transaction"
  }, {
    path: "/player/banks",
    component: _7394f650,
    name: "player-banks"
  }, {
    path: "/player/bet-history",
    component: _7651e4b9,
    name: "player-bet-history"
  }, {
    path: "/player/deposits",
    component: _7432c82c,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _edb8323e,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _09e6e787,
    name: "player-profile"
  }, {
    path: "/player/wallets",
    component: _5ed7ae10,
    name: "player-wallets"
  }, {
    path: "/player/withdrawals",
    component: _980d6782,
    name: "player-withdrawals"
  }, {
    path: "/providers/games",
    component: _e80a0e8a,
    name: "providers-games"
  }, {
    path: "/",
    component: _1a5f65f6,
    name: "index"
  }, {
    path: "/providers/games/:id",
    component: _79d5ede3,
    name: "providers-games-id"
  }, {
    path: "/Information/:id?",
    component: _3d0089db,
    name: "Information-id"
  }, {
    path: "/promotion/:id",
    component: _7ccb9572,
    name: "promotion-id"
  }, {
    path: "/providers/:id?",
    component: _6bea3b1e,
    name: "providers-id"
  }, {
    path: "/:type/:id?",
    component: _d75c9e30,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
